import { setCookie } from 'cookies-next'
import React, { FC, useEffect } from 'react'
import { poppins, useTranslation } from '../lib/utils'

interface CookieDialogProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isBadWebsite?: boolean
}

const CookieDialog: FC<CookieDialogProps> = ({
  isOpen,
  setIsOpen,
  isBadWebsite,
}) => {
  const t = useTranslation()

  // Fix to remove false positive aXe error (ruins our lighthouse score)
  useEffect(() => {
    // Hack to fix pa11y error
    let elements
    setTimeout(() => {
      elements = document.querySelectorAll(
        '.MuiDialog-container, .MuiModal-root'
      )
      elements?.forEach((e) => {
        e.removeAttribute('role')
      })
    }, 100)
  }, [isBadWebsite, isOpen])

  const acceptCookie = () => {
    setCookie('localConsent', 'true', { maxAge: 60 * 60 * 24 * 365 })
    setIsOpen(false)
  }
  const denyCookie = () => {
    setCookie('localConsent', 'false', { maxAge: 60 * 60 * 24 * 365 })
    setIsOpen(false)
  }

  return (
    isOpen ? (
    <div
      className={`fixed top-0 left-0 bottom-0 right-0 z-[1300] ${poppins.variable} font-sans`}
    >
      <div
        role="dialog"
        aria-labelledby="cookie-heading"
        className="fixed top-0 right-0 left-0 bottom-0 backdrop-blur-[6px] backdrop-grayscale focus-visible:outline-0"
      >
        <div
          className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-[rgba(0,0,0,0.5)] transition-opacity ${
            isOpen ? 'opacity-100' : 'opacity-0'
          }`}
        />
        <div className="flex h-full justify-center">
          <div className="mx-5 my-8 max-h-[calc(100%_-_64px)] max-w-[600px] space-y-3 self-end overflow-scroll rounded-t-2xl rounded-bl-2xl bg-white px-6 py-8 dark:border-[2px] dark:border-zinc-700 dark:bg-zinc-900 dark:text-white md:px-12 lg:self-start">
            <h1 className="text-3xl font-semibold" id="cookie-heading">
              {t.cookiesHeading}
            </h1>
            <p>{t.cookiesBody}</p>
            <div className="grid gap-4 pt-8 lg:grid-cols-2">
              <button
                type="button"
                className="rounded-xl bg-darkred px-6 py-4 text-xl text-white hover:brightness-95 focus-visible:brightness-95"
                onClick={() => acceptCookie()}
              >
                {t.cookiesAccept}
              </button>
              <button
                type="button"
                className="rounded-xl bg-darkred px-6 py-4 text-xl text-white hover:brightness-95 focus-visible:brightness-95"
                onClick={() => denyCookie()}
              >
                {t.cookiesDeny}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ): null)
}

export default CookieDialog

import '../styles/index.css'
import { ThemeProvider } from 'next-themes'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { getCookie, hasCookie } from 'cookies-next'
import { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { poppins } from '../lib/utils'
import CookieDialog from '../components/cookie-dialog'
// import '../components/snowfall.scss'

function MyApp({ Component, pageProps }: AppProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [consent, setConsent] = useState(false)

  useEffect(() => {
    setIsOpen(!hasCookie('localConsent'))
  }, [])

  useEffect(() => {
    if (!isOpen) if (getCookie('localConsent')) setConsent(true)
  }, [isOpen])

  return (
    <ThemeProvider attribute="class" enableSystem>
      <div className={`${poppins.variable} font-sans`}>
        {consent && (
          <Script id="google-tag-manager">
            {`
        window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTM_ID}');
          `}
          </Script>
        )}
        <DefaultSeo
          // titleTemplate={`${t.companyName} | %s`}
          description="Uselt är en parodi på tillgänglighetsbolaget Useit. Sajten används i utbildningar för att lära våra kunder att testa sina digitala upplevelser och hitta tillgänglighetsproblem."
          dangerouslySetAllPagesToNoIndex
          dangerouslySetAllPagesToNoFollow
        />
        <CookieDialog isOpen={isOpen} setIsOpen={setIsOpen} isBadWebsite />
        <Component {...pageProps} />
      </div>
    </ThemeProvider>
  )
}

export default MyApp

import { useEffect, useState } from 'react'
import { useTheme } from 'next-themes'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Poppins } from '@next/font/google'
import { useRouter } from 'next/router'
import { UseThemeProps } from 'next-themes/dist/types'
import {
  avatarStateType,
  countriesType,
  themeType,
  TranslationKeys,
  Translations,
} from './types'
import { BASE_TRANSLATIONS } from './constants'

export const poppins = Poppins({
  weight: ['400', '600'],
  subsets: ['latin'],
  variable: '--font-poppins',
})

export function useCurrentLocale(): countriesType {
  const router = useRouter()
  return router.locale as countriesType
}

// Hook to handle translations
export function useTranslation(lang?: countriesType): TranslationKeys {
  const locale = useCurrentLocale()
  return BASE_TRANSLATIONS[lang || locale]
}

export function useAllTranslations(): Translations {
  return BASE_TRANSLATIONS
}
// Hook to handle setting light or dark theme
export function usePreferredTheme() {
  const { theme, setTheme }: UseThemeProps = useTheme()
  const [preferredTheme, setPreferredTheme] = useState<themeType | undefined>(
    undefined
  )

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    if (theme === 'system') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setPreferredTheme('dark')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(window as any).dataLayer?.push({ event: 'darkmode_preferred' })
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        setPreferredTheme('light')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(window as any).dataLayer?.push({ event: 'lightmode_preferred' })
      }
    } else if (theme !== 'system') setPreferredTheme(theme as themeType)
  }, [theme])

  return { preferredTheme, setTheme }
}

export function useAvatarState() {
  const [avatarState, setAvatarState] =
    useState<avatarStateType>('illustrations')
  return { avatarState, setAvatarState }
}
// Function to get the correct URLs fron env variables
export const baseLanguageURLs = (language: countriesType) =>
  ({
    sv: process.env.USEIT_SE_URL,
    no: process.env.USEIT_NO_URL,
    en: `${process.env.USEIT_SE_URL}en/`,
  }[language])

// Master definition of path translations
export const localizedPaths = (language: countriesType) =>
  ({
    sv: {
      about: process.env.IS_BAD_WEBSITE === 'true' ? 'om-uselt' : 'om-useit',
      contact: 'kontakta-oss',
      services: 'vi-erbjuder',
      posts: 'aktuellt',
      confirmation: 'bekraftelse',
      newsletter: 'nyhetsbrev',
    },
    no: {
      about: process.env.IS_BAD_WEBSITE === 'true' ? 'om-uselt' : 'om-useit',
      contact: 'kontakt-oss',
      services: 'vi-tilbyr',
      posts: 'aktuelt',
      confirmation: 'bekreftelse',
      newsletter: 'nyhetsbrev',
    },
    en: {
      about: 'about',
      contact: 'contact',
      services: 'services',
      posts: 'posts',
      confirmation: 'confirmation',
      newsletter: 'newsletter',
    },
  }[language])

import { Translations } from './types'

export const SITE_URL = 'https://useit.se/'
export const COMPANY_NAME = process.env.IS_BAD_WEBSITE ? 'Uselt' : 'Useit'
export const ENGLISH = 'English'
export const SWEDISH = 'Svenska'
export const NORWEGIAN = 'Norsk'
export const BASE_TRANSLATIONS: Translations = {
  no: {
    companyName: 'Useit',
    sv: 'Sverige',
    no: 'Norge',
    darkMode: 'mørk modus',
    lightMode: 'lys modus',
    showIn: 'Vis i ',
    footer: 'Sidefot',
    current: 'Aktuelt hos Useit',
    readMore: 'Les mer',
    mainMenu: 'Hovedmeny',
    countryNav: 'Velg språk',
    goTo: 'Gå til',
    youAreHere: 'du er her',
    moreArticles: 'Flere artikler',
    goToStart: 'gå til startsiden',
    logo: 'logo',
    close: 'Lukk',
    menu: 'Meny',
    photos: 'Bilde',
    illustrations: 'Illustrasjoner',
    showIllustration: 'Vis illustrasjon',
    showPhoto: 'Vis foto',
    certified: '-sertifisert',
    chooseAvatarMode: 'Velg visning av profilbilder',
    skipToContent: 'Hopp til innholdet',
    contact: 'Kontakt',
    published: 'Publisert',
    footerContacts: 'Kontaktinformasjon',
    socialMedia: 'Sosiale medier',
    language: 'Språk',
    phone: 'Telefonnummer',
    email: 'Epostadresse',
    show: 'Vis',
    cookiesHeading: 'Vi bruker cookies',
    cookiesBody:
      'Vi bruker informasjonskapsler (cookies) for å samle inn anonym statistikk om hvordan nettsiden vår brukes. ',
    cookiesAccept: 'Godta cookies',
    cookiesDeny: 'Nei takk',
    fourohfourHeading: '404 - Vi kunne ikke finne siden',
    fourohfourParagraph:
      'Beklager, vi fant ikke siden du lette etter. Kanskje linken var feilstavet?',
    fourohfourCTA: 'Gå til startsiden',
    fourohfourAlt: 'Bruker ser på telefonen med utropstegn som øyne.',
    author: 'Forfatter',
    by: 'Av',
    showAllArticles: 'Vis alle artiklene',
    archive: 'Arkiv',
    swedish: 'svensk',
    norwegian: 'norsk',
    english: 'engelsk',
    readTheArticle: 'Les artikeln på ',
    or: 'eller',
    skipCodeBlock: 'Hopp over kodeblokken',
    codeBlock: 'Kodeblokke',
    codeBlockSkipped: 'Hoppet over kodeblokken',
    addToCalendar: 'Lägg till i kalendern',
    confirmationHeading: 'Takk!',
    confirmationText: '',
    moreFromUseit: 'Mer fra Useit',
    doYouWantToReadMore: 'Vil du lese mer?',
    signupForNewsletter: 'Abonner på Uselts nyhetsbrev',
    externalLink: 'Ekstern lenke',
    emailLink: 'E-post lenke',
    writtenBy: 'Skrevet av',
    readingTime: 'Lestid',
    minutes: 'minutter',
    otherArticleVersions: 'Andre versjoner av artikkelen',
    send: 'Send',
    mailChimpHeading: 'Takk, du er nå påmeldt vårt nyhetsbrev!',
    mailChimpParagraph1: 'Du vil straks motta en bekreftelse på din e-post.',
    mailChimpParagraph2:
      'Vi håper du lar deg inspirere med nyheter og innslag om universell utforming.',
    mailChimpParagraph3: 'Beste hilsen',
    mailChimpParagraph4: 'Oss alle i Useit',
  },
  sv: {
    companyName: process.env.IS_BAD_WEBSITE ? 'Uselt' : 'Useit',
    sv: 'Sverige',
    no: 'Norge',
    darkMode: 'mörkt läge',
    lightMode: 'ljust läge',
    showIn: 'Visa i ',
    footer: 'Sidfot',
    current: process.env.IS_BAD_WEBSITE
      ? 'Aktuellt hos Uselt'
      : 'Aktuellt hos Useit',
    readMore: 'Läs mer',
    mainMenu: 'Huvudmeny',
    countryNav: 'Välj språk',
    goTo: 'Gå till',
    youAreHere: 'du är här',
    moreArticles: 'Fler artiklar',
    goToStart: 'gå till startsidan',
    logo: 'logotyp',
    close: 'Stäng',
    menu: 'Meny',
    photos: 'Foton',
    illustrations: 'Illustrationer',
    showIllustration: 'Visa illustration',
    showPhoto: 'Visa foto',
    certified: '-certifierad',
    chooseAvatarMode: 'Välj visning av profilbilder',
    skipToContent: 'Hoppa till innehållet',
    contact: 'Kontakt',
    published: 'Publicerat',
    footerContacts: 'Kontaktuppgifter',
    socialMedia: 'Sociala medier',
    language: 'Språk',
    phone: 'Telefonnummer',
    email: 'E-postadress',
    show: 'Visa',
    cookiesHeading: 'Vi använder kakor',
    cookiesBody:
      'Vi använder kakor (cookies) för att samla in anonym statistik om hur vår hemsida används.',
    cookiesAccept: 'Acceptera kakor',
    cookiesDeny: 'Nej tack',
    fourohfourHeading: '404 - Sidan hittades inte',
    fourohfourParagraph:
      'Tyvärr kunde vi inte hitta sidan du letade efter. Kanske var länken felstavad?',
    fourohfourCTA: 'Gå till startsidan',
    fourohfourAlt:
      'Användare som tittar på sin telefon med utropstecken som ögon.',
    author: 'Författare',
    by: 'av',
    showAllArticles: 'Visa alla artiklar',
    archive: 'Arkiv',
    swedish: 'svenska',
    norwegian: 'norska',
    english: 'engelska',
    readTheArticle: 'Läs artikeln på ',
    or: 'eller',
    skipCodeBlock: 'Hoppa over exempelkod',
    codeBlock: 'Exempelkod',
    codeBlockSkipped: 'Hoppat över exempelkoden',
    addToCalendar: 'Lägg till i kalendern',
    confirmationHeading: 'Tack för din anmälan!',
    confirmationText: '',
    moreFromUseit: process.env.IS_BAD_WEBSITE
      ? 'Mer från Uselt'
      : 'Mer från Useit',
    doYouWantToReadMore: 'Vill du läsa mer?',
    signupForNewsletter: process.env.IS_BAD_WEBSITE
      ? 'Prenumerera på Uselts nyhetsbrev'
      : 'Prenumerera på Useits nyhetsbrev',
    externalLink: 'Extern länk',
    emailLink: 'E-post-länk',
    writtenBy: 'Skrivet av',
    readingTime: 'Lästid',
    minutes: 'minuter',
    otherArticleVersions: 'Andra versioner av artikeln',
    send: 'Skicka',
    mailChimpHeading: 'Tack, du prenumererar ni på vårt nyhetsbrev!',
    mailChimpParagraph1: 'Du kommer snart att få en bekräftelse på din e-post.',
    mailChimpParagraph2: '',
    mailChimpParagraph3: 'Vänliga hälsningar',
    mailChimpParagraph4: 'Alla oss på Useit',
  },
  en: {
    companyName: 'Useit',
    sv: 'Sweden',
    no: 'Norway',
    darkMode: 'dark mode',
    lightMode: 'light mode',
    showIn: 'Show in ',
    footer: 'Footer',
    current: 'News from Useit',
    readMore: 'Read more',
    mainMenu: 'Main menu',
    countryNav: 'Change language',
    goTo: 'Go to',
    youAreHere: 'you are here',
    moreArticles: 'More articles',
    goToStart: 'go to start page',
    logo: 'logotype',
    close: 'Close',
    menu: 'Menu',
    photos: 'Photos',
    illustrations: 'Illustrations',
    showIllustration: 'Show illustration',
    showPhoto: 'Show photo',
    certified: ' certified',
    chooseAvatarMode: 'Choose type of profile pictures',
    skipToContent: 'Skip to main content',
    contact: 'Contact',
    published: 'Published',
    footerContacts: 'Contact information',
    socialMedia: 'Social media',
    language: 'Language',
    phone: 'Phone number',
    email: 'Email address',
    show: 'Show',
    cookiesHeading: 'We use cookies',
    cookiesBody:
      'We use cookies to gather anonomous statistics about how our website is used.',
    cookiesAccept: 'Accept cookies',
    cookiesDeny: 'No thank you',
    fourohfourHeading: '404 - Page not found',
    fourohfourParagraph:
      'Unfortunately we could not find the requested page. Have you checked if the link is correct?',
    fourohfourCTA: 'Go to the start page',
    fourohfourAlt:
      'A user looking at their phone with exclamation marks as eyes.',
    author: 'Author',
    by: 'av',
    showAllArticles: 'Show all articles',
    archive: 'Archive',
    swedish: 'Swedish',
    norwegian: 'Norwegian',
    english: 'English',
    readTheArticle: 'Read the article in ',
    or: 'or',
    skipCodeBlock: 'Skip sample code',
    codeBlock: 'Sample code',
    codeBlockSkipped: 'Sample code skipped',
    addToCalendar: 'Add to calendar',
    confirmationHeading: 'Thank you!',
    confirmationText: '',
    moreFromUseit: 'More from Useit',
    doYouWantToReadMore: 'Do you want to read more?',
    signupForNewsletter: 'Sign up for our newsletter',
    externalLink: 'External link',
    emailLink: 'Email link',
    writtenBy: 'Written by',
    readingTime: 'Reading time',
    minutes: 'minutes',
    otherArticleVersions: 'Other versions of the article',
    send: 'Send',
    mailChimpHeading: 'Thank you, you are now subscribed to our newsletter!',
    mailChimpParagraph1: 'You will soon receive a confirmation email.',
    mailChimpParagraph2: '',
    mailChimpParagraph3: 'Best regards',
    mailChimpParagraph4: 'All of us at Useit',
  },
}
